@import "src/styles/main";

.popup{
  border: 1px solid #35394A;
  border-radius: 8px;
}

.buttonWrapper {
  margin-left: 12px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: rgba(54, 57, 66, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease;
  cursor: pointer;
  &:hover {
    background: linear-gradient(180deg, #ADB8E0 0%, #363942 100%);
    .buttonWrapper_inner {
      background: #35394a;
      .buttonWrapper_inner_icon {
        color: rgba(164, 169, 188, 1);
      }
    }
  }
  &_inner {
    height: 39px;
    width: 39px;
    border-radius: 50%;
    outline: none;
    border: none;
    background: #262a38;
    transition: background 0.3s ease;
    &_icon {
      pointer-events: none;
      font-size: 20px;
      color: $text-monarchy-gray-secondary-default;
    }
  }
}

.button {
  margin-left: 12px;
}

.buttonOuter {
  border-radius: 50%;

}

.buttonInner {
  border-radius: 50%;
  @include m{
    height: 30px;
  }
}

.icon {
  pointer-events: none;
  font-size: 20px;
  color: $text-monarchy-gray-secondary-default;
}
.checkbox {
  @include checkbox;
}

.checked {
  color: #FFFFFF;
}


.menu{
  border: none!important;
  :global{
    .ant-menu-item{
      padding-left: 8px!important;
      padding-right: 8px!important;
    }
  }
}
