@import 'src/styles/main';

.pageContainer {
  display: flex;
  flex-direction: column;
  @include m {
    padding: 16px 0 16px 0;
    min-height: 100vh;
  }
}

.header {
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 16px;
  margin-bottom: 32px;
  @include m {
    padding: 0 16px;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;
  }
}

.subHeader {
  display: flex;
  gap: 16px;
  margin-top: 8px;
}

.leftBlock {
  flex-grow: 1;
}

.headerText {
  color: $text-neutral-strong;
  @include Heading_xxl_32_600sb;
  display: block;
}

.period {
  @include m {
    display: none;
  }
}

.labelText {
  @include Heading_xs16_600sb;
  color: $text-monarchy-gray-secondary-default;
}

.valueText {
  @include Heading_xs16_600sb;
  color: $text-neutral-strong;

  &_green {
    color: $text-success-default;
  }

  &_red {
    color: $text-error-default;
  }
}

.inputWrapper {
  background: linear-gradient(180deg, #35394a 0%, #21242e 100%);
  padding: 1px;
  border-radius: 9px;
  @include m {
    width: 100%;
  }
}

.input {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #21242e;
  height: 40px;
  border-radius: 8px;
  padding-left: 16px;
  box-sizing: border-box;
  border: 1px solid;
  border-image-source: linear-gradient(180deg, #494F65 0%, #35394A 100%);

  @include m {
    width: 100%;
  }

  input {
    padding: 8px 14px 8px 8px;
    background-color: transparent;
    color: $text-neutral-strong;
    flex-grow: 1;
    border: none;
    outline: none;
    &::placeholder {
      color: $text-monarchy-gray-secondary-default;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

.itemsContainer {
  @include m {
    overflow-x: auto;
    overflow-y: visible;
    padding-left: 16px;
    flex-grow: 1;
    flex-shrink: 0;
  }
}
