@import "src/styles/main";
.customSlider {
  margin-top: 24px;
  padding-left: 10px;
  padding-right: 10px;
  :global {
    .ant-slider {
      height: 8px;
      border-radius: 8px;
      .ant-slider-rail{
        background: linear-gradient(90deg, #12B76A 0%, #FF9600 25.09%, #D06D1E 50.31%, #FF5347 75%, #C0180C 100%);
        height: 8px;
        border-radius: 8px;
        width: calc(100% + 20px);
        margin-left: -10px;
        transform: translateY(-4px);
      }
      &:hover, &:active {
        & .ant-slider-track {
          background: transparent;
        }
      }
    }
    .ant-slider-handle {
      position: absolute;
      top: -8px;
      left: 0;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-image: url('../../../../../assets/icons/handle.svg');
      background-size: 24px 24px;
      background-position: center;
      background-repeat: no-repeat;
      &::after {
        display: none;
      }
    }
    .ant-slider-track {
      background-color: transparent;
      height: 8px;
    }
    .ant-slider-rail {
      height: 8px;
    }
  }
}

.scale {
  margin-top: -2px;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;

  &_item {
    &_line {
      margin: 0 auto;
      height: 6px;
      width: 1px;
      background: $border-monarchy-gray-secondary-medium;
    }
  }
}

.bottom {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 17px;
  color:$text-monarchy-gray-secondary-default;
  font-weight: 600;
  & span {
    color: #fff;
    margin-right: 4px;
    margin-left: 4px;
  }
}
