$screen-s-min: 360px;
$screen-m-min: 500px;
$screen-l-min: 768px;
$screen-xl-min: 1040px;


// Small devices
@mixin s {
    @media (max-width: #{$screen-s-min}) {
        @content;
    }
}
// Medium devices
@mixin m {
    @media (max-width: #{$screen-m-min}) {
        @content;
    }
}
// Large devices
@mixin l {
    @media (max-width: #{$screen-l-min}) {
        @content;
    }
}
// Extra large devices
@mixin xl {
    @media (max-width: #{$screen-xl-min}) {
        @content;
    }
}

// Custom devices
@mixin rwd($screen) {
    @media (max-width: $screen+'px') {
        @content;
    }
}
