.wager {
  margin-top: 24px;
}

.label {
  font-size: 12px;
  line-height: 16px;
  color: #d7d8e0;
  margin-bottom: 8px;
}

.displayWrapper {
  background: linear-gradient(180deg, #494F65 0%, #35394A 100%);
  padding: 1px;
  border-radius: 8px;
}

.display {
  height: 50px;
  border: 1px solid var(--dark-monarchy-gray-secondary-200, rgba(54, 57, 66, 1));
  border-radius: 8px;
  padding: 0 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #15181E;
}

.price {
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  font-weight: 600;
  & input {
    max-width: 150px;
    background: #15181E;
    border: none;
    outline: none;
    appearance: none;
    color: #fff;
    text-align: center;
    position: relative;
  }
}

.buttonWrapper {
  position: relative;
  padding: 1px;
  border-radius: 8px;
  overflow: hidden;
  background: linear-gradient(180deg, #35394A 0%, #494F69 100%);
  transition: background 0.3s ease-in-out;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    background: conic-gradient(from 180deg at 50% 50%,
            #494F65 0deg,
            #494F65 55.45deg,
            #ADB8E0 130.37deg,
            #494F65 231.95deg,
            #868FB0 310.2deg,
            #494F65 360deg
    );
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover::before {
    opacity: 1;
  }
}

.button {
  width: 30px;
  height: 30px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  background: linear-gradient(180deg, #35394A 0%, #494F69 100%);
  position: relative;
  &_line {
    width: 14px;
    height: 2px;
    background: var(--icon-monarchy-gray-secondary-medium, rgba(215, 216, 224, 1));
    position: absolute;
    &_vertical {
      position: absolute;
      width: 2px;
      height: 14px;
      background: var(--icon-monarchy-gray-secondary-medium, rgba(215, 216, 224, 1));
    }
  }
}
