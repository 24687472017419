@import "breakpoints";

@mixin Heading_sm18_600sb {
  font-family: "RoobertPRO", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  text-align: left;
}
@mixin Heading_md20_600sb {
  font-family: "RoobertPRO", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
}

@mixin Heading_xs16_600sb {
  font-family: "RoobertPRO", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  text-align: left;
  @include m {
    font-size: 14px;
    line-height: 19.6px;
  }
}

@mixin Heading_xxs_14_600_sb{
  font-family: 'RoobertPRO', Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  text-align: left;
}

@mixin  Heading_lg_24_600_sb{
  font-family: 'RoobertPRO', Arial, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: left;
}

@mixin Heading_xxl_32_600sb {
  font-family: 'RoobertPRO', Arial, sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  text-align: left;
  @include m {
    font-size: 24px;
    line-height: 28.8px;
  }
}
