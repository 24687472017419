@import "src/styles/main";

.wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    .infoIcon {
      color: $neutral-white;
    }
  }
}

.infoIcon {
  font-size: 20px;
  color: $text-monarchy-gray-secondary-default;
}
