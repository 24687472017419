@import "src/styles/main";

.highlighted {
  color: $neutral-white;
}

.tableWrapper {
  @include m {
    //padding: 0 16px;
  }
  position: relative;
  margin-bottom: 20px;
}

.table {
  :global {
    th {
      @include m {
        font-size: 12px !important;
      }
    }

    .ant-table-cell {
      padding: 16px 8px !important;
      @include m {
        padding: 12px 8px !important;
      }
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
      //background: unset;
    }

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-left-first,
    .ant-table-cell-fix-right,
    .ant-table-cell-fix-right-first {
      &:after {
        box-shadow: inset -10px 0 10px -8px rgba(0, 0, 0, 0.4) !important;
      }
    }

    .ant-table-container::before
    {
      box-shadow: none !important;
      @include m {
        box-shadow: inset 10px 0 10px -8px rgba(0, 0, 0, 0.4) !important;
      }
    }
    .ant-table-container::after,{
      box-shadow: none !important;
    }
  }
}

.row {
  background-color: #21242E;
}

.bottom{
  position: absolute;
  height: 50px;
  bottom: 0;
  //border: #c21d1d 1px dashed;
  width: 10%;
  //z-index: -1;
}

//

