@import "../../../styles/colors";

.positive {
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: left;
  color: $text-success-default;
}

.negative {
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: left;
  color: #E34D44;
}

.neutral {
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: left;
  color: $text-monarchy-gray-secondary-default;
}
