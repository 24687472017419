@import 'src/styles/main';

.content {
  background-image: url('../../assets/images/how-it-works-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.header {
  padding: 32px 40px 0 40px;
  @include m {
    padding: 20px 20px 0 20px;
  }
  &_title {
    margin-top: 0;
   @include Heading_lg_24_600_sb;
    color: #fff;
    @include m {
      font-size: 18px;
      line-height: 25px;
    }
  }
}

.main {
  padding: 24px 40px 15px 40px;
  overflow: hidden;
  height: 330px;
  border-bottom: 1px solid $text-secondary-gray-weak;
  @include m {
    height: 530px;
    padding: 20px 20px 0 20px;
  }
}

.footer {
  padding: 12px 40px 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_button {
    width: 200px;
    @include m{
      width: calc(50% - 8px);
    }
  }

  @include m {
    padding: 2px 20px 0;
    flex-direction: column;
    gap: 14px;
    align-items: flex-start;
  }

  .checkbox {
    @include checkbox;
  }

  .checked {
    color: #FFFFFF;
  }

  .buttonsContainer{
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    @include m{
      width: 100%;
      gap: 16px;
    }
  }
}

.fadeEnter {
  opacity: 0;
  transition: opacity 600ms;
  transform: translateX(2000px);
}

.fadeEnterActive {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 600ms, transform 600ms ease-in-out;
}

.fadeExit {
  opacity: 1;
  transition: opacity 600ms;
  transform: translateX(0);
}

.fadeExitActive {
  opacity: 0;
  transform: translateX(-2000px);
  transition: opacity 600ms, transform 600ms ease-in-out;
}

.steps{
  margin-bottom: 24px;
  width: 370px;

  @include m{
    width: 100%;
  }

  :global{
    .ant-steps-item-icon{
      margin-inline-end: 0;
    }

    .ant-steps-item-finish{
      .ant-steps-item-icon{
        border: 2px solid #C0180C;
        background-color: #C0180C;
        .ant-steps-icon{
          color: #FFFFFF;
          top: -1.5px;
        }
      }
      .ant-steps-item-title{
        &:after{
          height: 2px;
        }
      }
    }

    .ant-steps-item-active{
      .ant-steps-item-title{
        &:after{
          background-color: #A1AEBE!important;
          height: 2px;
          opacity: 0.5;
        }
      }
    }

    .ant-steps-item-wait{
      opacity: 0.5;
      .ant-steps-item-icon{
        border: 2px solid #A1AEBE;
        background-color: #FFFFFF;
        .ant-steps-icon{
          color: #242E39;
          top: -1.5px;
        }
      }
      .ant-steps-item-title{
        &:after{
          background-color: #A1AEBE!important;
          height: 2px;
        }
      }
    }
  }
}
