@import 'src/styles/main';

.tableWrapper {
  flex: 1 1 70%;
  max-height: calc(100vh - 260px);
  overflow-y: scroll;
  @include l {
    max-height: unset;
    overflow: unset;
  }
}

.table {
  :global {
    .ant-table-wrapper,
    .ant-table-container::before,
    .ant-table-container::after {
      box-shadow: none !important;
    }
    .ant-table {
      overflow: auto !important;
      background: #21242E;
      ::-webkit-scrollbar {
        width: 7px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #222020;
        border-radius: 10px;
        border: 1px solid #727070;
      }
      ::-webkit-scrollbar-track {
        background-color: transparent;
      }
      .ant-table-body {
        overflow: auto !important;
      }
      .ant-table-tbody > tr.ant-table-row:hover > td {
        background: unset;
      }
      .ant-table-thead > tr > th {
        font-weight: bold;
        border-bottom: none;
        text-align: center;
        background: transparent;
        box-shadow: none;
        &:first-child {
          text-align: left;
        }
        &:last-child {
          text-align: right;
        }
        @include l {
          font-size: 12px;
        }
      }
      .ant-table-tbody > tr > td {
        text-align: center;
        border-bottom: none;
        color: #E34D44;
        font-size: 14px;
        font-weight: 500;
        line-height: 19.6px;
        &:first-child {
          text-align: left;
        }
        &:last-child {
          text-align: right;
        }
        padding: 4px 16px;
        height: 32px;

      }
      .ant-table-tbody > tr:nth-child(odd) {
        background: #2D313F80;
      }
    }
  }
}

