@import "src/styles/main";

.container {
  position: absolute;
  top: -5000px;
  z-index: 10000;
  right: 0;
  padding: 0;
  margin: 0;
  background: transparent;
}

.wrapper {
  width: 650px;
  height: 400px;
  background: linear-gradient(248.87deg, #1A202D 0%, #000000 98.56%);
  text-align: left;
  //border-radius: 16px;
  overflow: hidden;
}

.inner {
  height: 100%;
  padding: 40px;
  background-image: url('../../assets/images/share-coins.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.main {
  margin-top: 24px;
  &_top {
    display: flex;
    align-items: center;
    gap: 8px;
    &_left {
      display: flex;
      align-items: center;
      gap: 8px;
      & img {
        max-width: 20px;
      }
      &_text {
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        color: $text-secondary-gray-default;
      }
    }
    &_right {
      background: #07452880;
      border-radius: 8px;
      color: #12B76A;
      font-size: 12px;
      font-weight: 600;
      line-height: 14.52px;
      padding: 5.5px 8px;
      display: flex;
      & svg {
        width: 12px;
        height: 12px;
        margin-right: 2px;
        margin-top: 1px;
      }
      &_down {
        background: rgba(96, 12, 12, 0.5);
        color: rgba(255, 83, 71, 1)
      }
    }
  }
  &_center {
    font-size: 48px;
    font-weight: 500;
    line-height: 48px;
    color: #12B76A;
    margin-top: 20px;
    &_red {
      color: rgba(255, 83, 71, 1);
    }
  }
  &_bottom {
    margin-top: 20px;
    display: flex;
    gap: 60px;
    align-items: center;
    &_price {
      font-size: 16px;
      font-weight: 600;
      line-height: 22.4px;
      color: #fff;
    }
    &_text {
      font-size: 14px;
      font-weight: 600;
      line-height: 19.6px;
      color: $text-secondary-gray-default;
    }
  }
}

.bottom {
  margin-top: 34px;
  display: flex;
  align-items: center;
  gap: 24px;
  &_qrcode {
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background: linear-gradient(270deg, #9E42FB 0%, #F45D52 100%);
    &_inner {
      border-radius: 16px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px;
    }
  }
  &_text {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #FFFFFF;
    max-width: 178px;
    & span {
      font-weight: 700;
    }
  }
}

