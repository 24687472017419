@import 'src/styles/main';

.radioGroup {
  display: flex;
  justify-content: space-between;
}

.customRadio {
  &_inner {
    display: flex;
    align-items: center;
    gap: 4px;
    &_label {
      margin-top: -2px;
      color: $text-secondary-gray-default;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      &_checked {
        color: $text-neutral-strong;
      }
    }
    & svg {
      fill: #9096AF;
      width: 16px;
      height: 16px;
    }
  }
  :global {
    .ant-radio {
      .ant-radio-inner {
        border-color: #494F65;
        width: 16px;
        height: 16px;
      }
      &.ant-radio-checked {
        .ant-radio-inner {
          border-color: #12B76A;
          background: #15181E;
        }
        .ant-radio-inner::after {
          background-color: #12B76A;
        }
      }
    }
  }
}

.text {
  color: $text-secondary-gray-default;
  font-size: 11px;
  line-height: 15px;
}