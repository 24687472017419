@import "../../../styles/colors";

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}

.coinName {
  font-size: 14px;
  font-weight: 600;
  //line-height: 19.6px;
  line-height: 14px;
  text-align: left;
  color: $text-neutral-strong;
}

.currencyName {
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  text-align: left;
  color: $text-monarchy-gray-secondary-default;
}

.imageWrapper{
  height: 20px;
  width: 38px;
  position: relative;
}

.coinImage{
  height: 20px;
  width: 20px;
  z-index: 10;
  position: absolute;
  border-radius: 50%;
}
.flagImage{
  height: 14px;
  width: 14px;
  left: 16px;
  z-index: 0;
  position: absolute;
  bottom: 0;
}

.arrowIcon{
  font-size: 12px;
  margin-left: 8px;
}

.up{
  color: $text-success-default;
}

.down{
  color: $text-error-default;
}
