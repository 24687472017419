.wrapper {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.textBlock {
  max-width: 225px;
  text-align: left;
  &_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22.4px;
    color: #fff;
  }
  &_text {
    margin-top: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: rgba(215, 216, 224, 1);
  }
}