@import "src/styles/main";

.selector {
  @include m {
    height: 30px;
    pointer-events: none;
  }

  :global {
    .ant-select-selector {
      background-color: #35394A !important;
    }
  }
}

.menu{
  border: none!important;
  :global{
    .ant-menu-item{
      padding-left: 8px!important;
      padding-right: 8px!important;
    }
  }
}
