@import 'src/styles/main';

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background: linear-gradient(180deg, #0D0E12 0%, #16181F 100%);
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 4.5px solid #445764;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  margin-top: 16px;
  color: #D7D8E0;
  @include Heading_sm18_600sb;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0 0;

  &_inner{
    margin: 31px 0 31px;
  }

  .loader {
    width: 30px;
    aspect-ratio: 1;
    border: 3px solid #9096AF66;
    border-radius: 50%;
    position: relative;
    transform: rotate(45deg);
  }
  .loader::before {
    content: "";
    position: absolute;
    inset: -3px;
    border-radius: 50%;
    border: 3px solid #9096AF;
    animation: l18 1s infinite linear;
  }
  @keyframes l18 {
    0%   {clip-path:polygon(50% 50%,0 0,0    0,0    0   ,0    0   ,0    0   )}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0   ,100% 0   ,100% 0   )}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0    100%,0    100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0    100%,0    0   )}
  }
}
