@import "src/styles/main";

.betWidgetWrapper {
  position: relative;
  @include l {
    position: initial;
  }
  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    right: -33px;
    bottom: -280px;
    width: 394px;
    height: 350px;
    background-image: url('../../../../public/bg_logo.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom right;
    @include l {
      display: none;
    }
  }
}

.betWidget {
  //background: #35394A;
  background: linear-gradient(180deg, #35394A 0%, #262938 100%);
  padding: 16px;
  border-radius: 8px;
  flex-shrink: 0;
  width: 280px;
  transition: padding-top ease-in-out 300ms;
  box-shadow: 0 1px 2px 0 #0D0E120D;
  position: relative;
  @include l {
    position: initial;
  }
  @include m {
    background-color: transparent;
    width: 100%;
  }

  &_closed {
    @include m{
      transition: padding-top ease-in-out 300ms;
      padding-top: 8px;
    }
  }
}

.compute{
  position: absolute;
  visibility: hidden;
}

.closed{
  transition: all 300ms ease-in-out;
  @include m {
    overflow-y: hidden;
  }
}

.opened{
  transition: height 300ms ease-in-out;
  @include m {
    overflow-y: hidden;
  }
}

.autoBetContainer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
  &_active {
    max-height: 500px;
    opacity: 1;
  }
}




