@import "src/styles/main";

.positive {
  @include Heading_xs16_600sb;
  color: $text-success-default;
}

.negative {
  @include Heading_xs16_600sb;
  color: #E34D44;
}

.neutral {
  @include Heading_xs16_600sb;
  color: $text-monarchy-gray-secondary-default;
}
