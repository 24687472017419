@import "src/styles/main";

.driver{
  background-color: transparent!important;
  :global{
    .ant-drawer-body{
      background-color: #35394A;
      border-top: 1px solid $border-secondary-gray-medium;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      padding: 24px 16px 16px;
      overflow: hidden;
    }
  }
}

.draggerArea{
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .bar{
    background: #9096AF;
    width: 40px;
    height: 3px;
    border-radius: 100px;
  }
}

.menu {
  &_item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 7px 8px;
    font-size: 16px;
    line-height: 25px;
    font-weight: 600;
    color: $neutral-white;
    background: transparent;
    cursor: pointer;
    border-radius: 6px;
    width: 100%;
    &:hover {
      background: #21242E;
    }

    margin-bottom: 4px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
