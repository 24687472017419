@import "./colors";
@import 'breakpoints';

@mixin message {
  padding: 50px;
  width: 500px;
  color: #c21d1d;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

@mixin button{
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  color: $neutral-white;
  height: 44px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &[disabled]{
    background: $dark-secondary-gray;
    border: 1px solid #363942;
    color: var(--text-secondary-gray-disable, #4B5063);
    cursor: initial;
  }
}

@mixin primary_red_button {
 @include button;
  background: $button-primary-red;

  &:hover {
    background-color: $button-primary-red-hover;
  }

  &:active {
    background-color: $button-primary-red-focus;
  }

  &:focus {
    background: $button-primary-red;
    border: 1px solid $button-primary-red-focus;
    box-shadow: 0px 0px 0px 4px #911209;
  }
  &[disabled]{
    border: 1px solid #363942;
    color: var(--text-secondary-gray-disable, #4B5063);
  }
}

@mixin primary_green_button {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: $neutral-white;
  height: 44px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $button-green;
  //text-shadow: 0 2px 1px #00000080;

  &:hover {
    background: $button-primary-green-hover;
  }
  &:active {
    background-color: $button-primary-green-hover;
  }
  &:focus {
    border: 4px solid $button-primary-green-focus;
  }
  &[disabled]{
    background: var(--dark-secondary-gray-200, #2C2F3C);
    border: 1px solid #363942;
    color: var(--text-secondary-gray-disable, #4B5063);
    cursor: initial;
  }
}

@mixin secondary_button {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: $neutral-white;
  height: 44px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $button-secondary;

  &:hover {
    background: $button-secondary-hover;
  }
  &:active {
    background-color: $button-secondary-active;
  }
  &:focus {
    border: 4px solid $button-secondary-focus;
  }
  &[disabled]{
    background: var(--dark-secondary-gray-200, #2C2F3C);
    border: 1px solid #363942;
    color: var(--text-secondary-gray-disable, #4B5063);
    cursor: initial;
  }
}

@mixin borderedModal{
  :global{
    .ant-modal-content{
      border: $border-secondary-gray-medium 1px solid;
    }
  }
}

@mixin checkbox{
  padding: 9px 10px;
  border-radius: 4px;
  font-weight: 500;

  :global {
    .ant-checkbox-inner {
      border: solid 1px #494F65 !important;
      background-color: transparent !important;
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: transparent !important;
        border: solid 1px #12B76A !important;
      }
    }
  }

  &:hover {
    background: #35394A;
    padding: 9px 10px;
    border-radius: 4px;
  }
}
