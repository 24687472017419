@import 'src/styles/main';

.feeOptions {
  margin-top: 24px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &_text {
    font-size: 14px;
    //line-height: 24px;
    font-weight: 600;
    color: #acb0c3;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    padding: 4px 8px;
    max-width: 120px;
    border-radius: 8px;
    transition: 0.3s all;
    &:hover {
      transition: 0.3s all;
      background: rgba(44, 47, 60, 1);
    }

    &_active {
      color: $neutral-white;
    }
  }

  &_icon {
    color: var(--icon-monarchy-gray-secondary-default, #9096AF);
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: #fff;
    }

    &_active {
      color: #fff;
    }
  }
}

.main {
  margin-top: 14px;

  &_text {
    margin-top: 8px;
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    color: var(--text-secondary-gray-default, rgba(144, 150, 175, 1));
  }
}

.radioGroup {
  display: flex;
  gap: 14px;
}

.customRadio {
  &_inner {
    display: flex;
    align-items: center;
    gap: 4px;

    &_label {
      margin-top: -2px;
      color: $text-secondary-gray-default;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;

      &_checked {
        color: $text-neutral-strong;
      }
    }

    & svg {
      fill: #9096AF;
      width: 16px;
      height: 16px;
    }
  }

  :global {
    .ant-radio {
      .ant-radio-inner {
        border-color: #494F65;
        width: 16px;
        height: 16px;
      }

      &.ant-radio-checked {
        .ant-radio-inner {
          border-color: #12B76A;
          background: #15181E;
        }

        .ant-radio-inner::after {
          background-color: #12B76A;
        }
      }
    }
  }
}

.tooltipText{
  font-size: 12px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: $text-secondary-gray-medium;
}

.tooltipDescription {
  font-size: 12px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: $text-secondary-gray-medium;
  display: block;
  margin-bottom: 8px;
}

.linkText {
  text-decoration: underline;
  cursor: pointer;
  color: $neutral-white;
}

.questionMarkIcon {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 3px;
  &:hover {
    background: rgba(44, 47, 60, 1);
    & svg {
      color: rgba(172, 176, 195, 1);
    }
  }
}

