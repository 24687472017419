@import '../../styles/colors';

.buttonsContainer {
  display: flex;
  align-items: center;
}

.icon {
  font-size: 22px;
  color: $text-monarchy-gray-secondary-default;
}

.iconButton {
  background: transparent;
  height: 34px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  &:hover {
    .icon {
      color: $neutral-white;
    }
  }

  &:active,
  :focus {
    .icon {
      opacity: 0.8;
    }
  }
}
