@import 'src/styles/main';

.header {
  &_top {
    display: flex;
    align-items: center;
    gap: 15px;
    @include l {
      flex-direction: column;
      align-items: flex-start;
    }
    &_title {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
    &_select {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      width: 153px;
      @include l {
        width: 100%;
      }
    }
  }
  &_text {
    margin-top: 12px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: $text-secondary-gray-medium;
    @include l {
      font-size: 12px;
      line-height: 16.8px;
    }
  }
}

.main {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 27px;
  @include l {
    flex-direction: column;
    margin-top: 16px;
    gap: 16px;
  }
}

.footer {
  padding-top: 20px;
  border-top: 1px solid $text-secondary-gray-weak;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  &_text {
    color: $text-secondary-gray-default;
    font-size: 14px;
    line-height: 22px;
    @include l {
      font-size: 11px;
      font-weight: 400;
      line-height: 15.4px;
      text-align: center;

    }
  }
  &_button {
    width: 200px;
    @include l {
      margin-left: auto;
      width: 120px;
    }
  }
  @include l {
    flex-direction: column;
  }
}
