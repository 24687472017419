@import 'src/styles/main';

.modal{
  @include borderedModal;
}

.content {
  border-bottom: 1px solid #35394A;
  padding-bottom: 12px;
}

.footer{
  display: flex;
  gap: 16px;
  position: relative;
}

.confirmButton {
flex-grow: 1;
  .icon {
    font-size: 22px;
  }
}

.cashOutButton{
  flex-grow: 1;
}

.settingsButton{
  width: 44px;
  flex-shrink: 0;
}

.buttonIcon{
  font-size: 22px;
  color: $neutral-white;
}

.header {
  margin-bottom: 20px;
}

.betHeaderBlock {
  background: $Color-700;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  flex-grow: 0;
}

.fullWidth {
  width: 100%;
  margin-bottom: 12px;
}

.label {
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  text-align: left;
  color: $text-monarchy-gray-secondary-default;
  flex-grow: 2;
  display: block;
}

.value {
  @include Heading_xs16_600sb;
  color: $neutral-white;
}

.status{
  @include Heading_xs16_600sb;
}

.green {
  color: $text-success-default;
}

.coinImage {
  height: 24px;
  width: 24px;
  border-radius: 50%;
}

.userImage {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.arrow {
  font-size: 20px;
  margin-left: 8px;

  &_up {
    color: $text-success-default;
  }
  &_down {
    color: $text-error-default;
  }
}

.column {
  display: flex;
  flex-direction: column;
  width: 50%;
  flex-shrink: 0;
}
