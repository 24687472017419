@import "/src/styles/main";

.price{
  @include Heading_md20_600sb;
  margin-left: 32px;
  color: $text-monarchy-gray-secondary-default;
  min-width: 130px;
  @include m{
    margin-left: 12px;
    @include Heading_xs16_600sb;
    min-width: 100px;
  }

  .icon{
    margin-left: 4px;
  }

  &_down{
    color: $dark-error-800;
  }

  &_up {
    color: $text-success-default;
  }
}
