@import "src/styles/main";

.content {
  border-bottom: 1px solid #35394A;
  padding-bottom: 20px;
}

.header {
 @include Heading_sm18_600sb;
  padding-bottom: 21px;
  @include m{
    border-bottom: #35394A solid 1px;
  }
}

.error {
  margin-top: 8px;
  text-align: left;
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
  color: $text-error-default;
}
