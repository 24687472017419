@import "src/styles/main";

.pageContainer {
  display: flex;
  flex-direction: column;
}

.betContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-shrink: 0;
  margin-bottom: 40px;
  @include l {
    gap: 8px;
  }
}

.tableContainer {
  flex-grow: 2;
  //margin-top: 16px;
  @include l {
    margin-top: 10px;
  }
  @include m {
    //margin-top: 16px;
  }
}

.chartContainer {
  background: #0a0a0e;
  padding: 24px;
  border-radius: 8px;
  flex-grow: 1;
  box-shadow: 0 1px 2px 0 #0D0E120D;
}

.splitter {
  height: calc(100vh - 89px);

  :global {
    .ant-splitter-bar-dragger {
      &::after {
        top: -10px !important;
        width: 40px !important;
        border-radius: 3px;
      }
    }
    .ant-splitter-panel{
      padding: 0;
    }
  }
}

.chartContainerMobile {
  background-color: #21242e;
  padding: 16px 16px 8px 16px;
  margin: 0 16px 0;
  border-radius: 8px;
  height: calc(100% - 5px);
  position: relative;
}

.menuButton{
  position: absolute;
  padding: 8px;
  bottom: -4px;
  right: 4px;
}

