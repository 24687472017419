@import 'src/styles/main';

.label {
  font-size: 12px;
  line-height: 16px;
  color: #d7d8e0;
  margin-bottom: 8px;
}

.input {
  height: 50px;
  flex: 1 1 100%;
  background: var(--neutral-black, rgba(10, 10, 14, 1));
  border: 1px solid var(--dark-monarchy-gray-secondary-200, rgba(54, 57, 66, 1));
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  overflow: hidden;
  position: relative;
  & input {
    padding: 0 14px;
    width: 100%;
    height: 100%;
    background: var(--neutral-black, rgba(10, 10, 14, 1));
    border: none;
    outline: none;
    appearance: none;
    color: #fff;
    &::placeholder {
      font-size: 16px;
      line-height: 22px;
      color: $text-monarchy-gray-secondary-default;
    }
  }
  &_prefix {
    & input {
      padding-left: 30px;
    }
  }
  & span {
    position: absolute;
    margin-left: 14px;
    color: $text-secondary-gray-default;
  }
}

.error {
  color: $text-error-default;
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
}