@import 'src/styles/main';

.content {
  background-image: url('../../assets/images/risk-warning-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 1px 2px 0 #0D0E120D;
  @include m {
    background-image: url('../../assets/images/modal-bg-mobile.png');
  }
}

.header {
  padding: 32px 40px 0 40px;
  @include m {
    padding: 20px 40px 0 20px;
  }

  &_title {
    @include Heading_lg_24_600_sb;
    color: #fff;
  }
}

.main {
  padding: 30px 40px;
  max-height: calc(100vh - 310px);
  overflow-y: scroll;
  border-bottom: 1px solid $text-secondary-gray-weak;
  @include m {
    padding: 20px 20px 20px 20px;
    max-height: calc(100vh - 240px);
  }

  .mainContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 60px;
    @include m {
      flex-direction: column;
      gap: 20px;
    }

    .subheader {
      background: #FFFFFF0F;
      margin-bottom: 24px;
      border-radius: 8px;
      padding: 12px;
      font-size: 14px;
      font-weight: 700;
      line-height: 19.6px;
      color: $neutral-white;

      @include m {
        font-size: 12px;
        line-height: 17px;
      }
    }
  }


  &_image {
    position: relative;
    margin-left: 30px;
    height: 180px;


    .coin {
      position: absolute;
      //bottom: -25px;
      bottom: -18px;
      left: -55px;
      width: 90px;
    }

    @include m {
      max-height: 178px;
    }
  }

  .text {
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    color: $text-secondary-gray-medium;
  }

  .block {
    margin-top: 24px;
    background: #FFFFFF0F;
    color: $text-secondary-gray-medium;
    padding: 12px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
    @include m {
      margin-top: 12px;
      gap: 8px;
    }

    .text {
      @include m {
        font-size: 12px;
        line-height: 17px;
      }
    }
  }
}

.footer {
  padding: 12px 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include m {
    padding: 2px 24px 0;
    flex-direction: column;
    gap: 14px;
    align-items: flex-start;
  }

  &_button {
    width: 200px;
    @include m{
      width: 100%;
    }
  }

  .checkbox {
    @include checkbox;
  }

  .checked {
    color: #FFFFFF;
  }
}
