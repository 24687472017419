@import 'src/styles/main';

.container {
  position: absolute;
  transform: translate(-50%, -50%);
  padding: 12px;
  width: 131px;
  height: 94px;
  border: 1px solid #4B5063;
  background-color: #35394A;
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  transition: 0.3s ease;
  z-index: 10;

  & .header {
    display: flex;
    align-items: center;

    & .cryptoImage {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    & .title {
      margin-right: auto;
      font-weight: 500;
      font-size: 14px;
    }

    & .arrowIcon {
      color: $text-success-default;
    }

    & .arrowDownIcon {
      color: $dark-error-800;
      transform: rotate(-180deg);
    }
  }

  & .bottom {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    & .price {
      font-weight: 600;
      color: $text-secondary-gray-default;
    }

    & .markedPrice {
      font-weight: 600;
      margin-left: 10px;
    }

    & .priceDown {
      color: $dark-error-800;
    }

    & .priceUp {
      color: $text-success-default;
    }

    & .priceZero {
      color: $text-monarchy-gray-secondary-default
    }
  }
}