.item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.coinImage {
  max-height: 20px;
  max-width: 20px;
}

.customSelect {
  border-radius: 8px;
  :global {
    .ant-select-selector {
      background: #35394A !important;
      color: #fff !important;
      border: none !important;
      box-shadow: none !important;
      border-radius: 4px;

      &:hover {
        background-color: #35394A;
        border: none !important;
        box-shadow: none !important;
      }

      .ant-select-selection-item {
        color: #fff !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .ant-select-dropdown {
      .ant-select-item {
        padding: 8px 16px;

        &:hover {
          background-color: transparent !important;
        }

        .ant-select-item-option-content {
          display: flex;
          align-items: center;
        }
      }

      .ant-select-item-option-selected {
        color: #fff !important;
      }
    }

    .ant-select-arrow {
      color: #9096AF !important;
    }
  }
}
