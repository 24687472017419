@import "src/styles/main";
.tvChartWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.tvChart {
  position: relative;
  width: 100%;
  height: 100%;
}

.chart_container{
  position: relative;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  top: calc(50% - 60px);

  .loader {
    width: 64px;
    aspect-ratio: 1;
    border: 5px solid #9096AF66;
    border-radius: 50%;
    position: relative;
    transform: rotate(45deg);
  }
  .loader::before {
    content: "";
    position: absolute;
    inset: -5px;
    border-radius: 50%;
    border: 5px solid #9096AF;
    animation: l18 1.5s infinite linear;
  }
  @keyframes l18 {
    0%   {clip-path:polygon(50% 50%,0 0,0    0,0    0   ,0    0   ,0    0   )}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0   ,100% 0   ,100% 0   )}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0    100%,0    100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0    100%,0    0   )}
  }
}

.error {
  min-height: 460px;
}

.toNowButton{
  position: absolute;
  right: 85px;
  bottom: 60px;
  height: 25px;
  width: 25px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #FFFFFF22;
  transition: all 300ms ease-in-out;
  box-shadow: #00000055 2px 2px 7px 1px;
  color: $text-monarchy-gray-secondary-default;
  z-index: 100;
  &:hover{
    color: $text-secondary-gray-medium;
  }
}
