.container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.left{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
