@import 'normalize.css/normalize.css';

@font-face {
  font-family: 'RoobertPRO';
  src: local('RoobertPRO'), url(./assets/fonts/RoobertPROTRIAL-SemiBold.otf) format('opentype');
}

body {
  background-color: #0a0a0e;
  font-family: 'Inter', sans-serif;
  overscroll-behavior: none;
}

div{
  overscroll-behavior: none;
}

div,
span,
button {
  box-sizing: border-box;
}
button,
button:active,
button:focus {
  border: none;
  outline: none;
  cursor: pointer;
}

a {
  text-decoration: none;
}

/*Scrollbar*/
/* Style for Chrome, Edge, and Safari */
::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #222020;
  border-radius: 10px;
  border: 1px solid #727070;
}


.ant-table-body {
 scrollbar-width: auto;
 scrollbar-color: auto;
}

.ticket-enter {
  opacity: 0;
  transform: translateX(0%);
  transition: all 500ms ease-in-out, opacity 400ms ease-in-out;
}

.ticket-enter-active {
  opacity: 1;
  transform: translateX(0) scale(1);
  transition: all 500ms ease-in-out, opacity 400ms ease-in-out;
}

.ticket-exit {
  opacity: 1;
  transition: all 500ms ease-in-out, opacity 400ms ease-in-out;
}

.ticket-exit-active {
  opacity: 0;
  transform: translateX(100%) scale(.5);
  transition: all 500ms ease-in-out, opacity 400ms ease-in-out;

}

.ticket-exit-active {
  transition: all 500ms ease-in-out, opacity 400ms ease-in-out;
}

.ticket-enter-active {
  transition: all 100ms ease-in-out, opacity 400ms ease-in-out;
}

.ant-tooltip-arrow:after{
  border: solid 1px #35394A;
}
