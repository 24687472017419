@import 'src/styles/main';

.dashboard {
  flex: 1 1 30%;
  max-height: calc(100vh - 260px);
  overflow-y: scroll;
  padding-right: 5px;
  min-width: 250px;
  @include l {
    max-height: unset;
    padding-right: 0;
    overflow: unset;
  }
}

.item {
  margin-top: 12px;
}

