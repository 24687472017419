$text-neutral-strong: #ffffff;
$text-monarchy-gray-secondary-default: #9096af;
$text-monarchy-gray-secondary-medium: #d7d8e0;
$border-monarchy-gray-secondary-medium: #5E6272;
$text-error-default: #ff5347;
$text-success-default: #12b76a;
$text-secondary-gray-weak: #35394A;
$dark-secondary-gray: #2C2F3C;
$text-secondary-gray-disable: #4B5063;
$text-secondary-gray-default: #9096AF;
$text-secondary-gray-medium: #D7D8E0;
$border-secondary-gray-medium: #4B5063;
$neutral-white: #ffffff;

$button-secondary-default: #4d505e;
$button-green-secondary: #009952;
$button-green-secondary-hover: #11aa62;

$button-primary-red: #C0180C;
$button-primary-red-hover: #D8271B;
$button-primary-red-focus: #911209;
$button-primary-red-active: #9C0F04;

$button-green: #0E8A50;
$button-primary-green-hover: #269662;
$button-primary-green-active: #0D7C48;
$button-primary-green-focus: #0A6239;

$button-secondary: #35394A;
$button-secondary-hover: #4B5063;
$button-secondary-focus: #4B5063;
$button-secondary-active: #35394A;

$Color-600: #21242E;
$Color-500: #35394A;
$Color-700: #15181E;
$Color-200: #ACB0C3;

$dark-error-700: #EC4D42;
$dark-error-800: #FF5347;
$dark-success-600: #0F9B5A;

