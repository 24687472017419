.socialNetworks {
  position: absolute;
  top: -35px;
  display: flex;
  justify-content: center;
  gap: 10px;
  left: 50%;
  transform: translateX(-50%);
  & svg {
    max-width: 30px;
    max-height: 30px;
  }
}