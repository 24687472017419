@import "src/styles/main";

.mainContainer {
  position: relative;
}

.tableContainer {
  width: 100%;
  margin: 0 auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  @include rwd(1075) {
    overflow: auto;
  }
}

.headerRow  {
  display: grid;
  grid-template-columns:
          minmax(150px, 1.3fr)
          minmax(80px, 1fr)
          minmax(80px, 1fr)
          minmax(110px, 1fr)
          minmax(110px, 1fr)
          minmax(110px, 1fr)
          minMax(80px, 1fr)
          minMax(80px, 1fr)
          minMax(36px, 1fr);
  background-color: #35394a;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  z-index: 1000;
}

.dataRow {
  display: grid;
  grid-template-columns:
          minmax(150px, 1.3fr)
          minmax(80px, 1fr)
          minmax(80px, 1fr)
          minmax(110px, 1fr)
          minmax(110px, 1fr)
          minmax(110px, 1fr)
          minMax(80px, 1fr)
          minMax(80px, 1fr)
          minMax(36px, 1fr);
  position: relative;
  z-index: 1000;

  &:hover div {
    background: #2f2e36;
  }
}

.listContainer {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}

.cell {
  display: flex;
  align-items: center;
  padding: 16px 8px;
  height: 55px;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'
}

.dataCell {
  border-bottom: 1px solid #4D505E;
  transition: background 0.2s, border-color 0.2s;
  color: #D7D8E0;
  font-size: 14px;
  background-color: #21242e;

  @include rwd(1074) {
    &:last-child {
      position: sticky;
      right: 0px;
      background: #2E2F37;
      z-index: 2;
    }
  }
}

.headerCell {
  color: #9096AF;
  font-weight: 600;
  text-align: start;
  background: #35394a;
  border-bottom: 1px solid #4D505E;
  transition: background 0.2s ease;
  font-size: 14px;

  @include rwd(1074) {
    &:last-child {
      position: sticky;
      right: 0px;
      background: #35394a;
      z-index: 2;
      border-top-right-radius: 8px;
      box-shadow: 2px 2px 10px 5px #0006;
    }

    &:last-child :after {
    }
  }

  &:first-child {
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
  }
}



.highlighted {
  color: $neutral-white;
}

.noDataPlaceholder {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  top: 55px;
  left: 0;
  right: 0;
  background: #2E2F37;
  height: 124.5px;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}