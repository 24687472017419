@import "src/styles/main";

.wrapper{
  border-radius: 50%;
  width: 23px;
  height: 23px;
  border: $border-monarchy-gray-secondary-medium 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $text-monarchy-gray-secondary-medium;
  font-size: 10px;
}

.icon{
  font-size: 24px;
}
