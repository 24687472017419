@import 'src/styles/main';

.placeBet {
  margin-top: 24px;
  @include m {
    margin-top: 8px;
  }
}


.button {
  height: 44px;
  width: 100%;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 1);
  padding: 2px;
  transition: 0.3s all;
  background: linear-gradient(180deg, rgba(32, 192, 118, 0.8) 0%, rgba(0, 153, 82, 0.8) 100%);

  &_inner {
    width: 100%;
    height: 100%;
    background: radial-gradient(50% 50% at 50% 50%, #23C97C 0%, #009952 100%);
    box-shadow: 0 0 6px 0 rgba(119, 255, 97, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }
  &:hover {
    .button_inner {
      background: radial-gradient(50% 50% at 50% 50%, #10A45F 0%, #007841 100%);
    }
  }
  &_down {
    background: linear-gradient(180deg, rgba(241, 39, 25, 0.8) 0%, rgba(192, 24, 12, 0.8) 100%);
    &_inner {
      background: radial-gradient(50% 50% at 50% 50%, #F43E31 0%, #C0180C 100%);
      box-shadow: 0 0 6px 0 rgba(255, 108, 97, 0.3);
    }
    &:hover {
      .button_inner {
        background: radial-gradient(50% 50% at 50% 50%, #E42C1F 0%, #B11005 100%);
      }
    }
  }
}

.error {
  margin-top: 8px;
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
  color: $text-error-default;
}
