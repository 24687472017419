@import "src/styles/main";

.wrapper {
  margin-bottom: 4px;
  display: flex;
  gap: 4px;

  span {
    font-size: 12px;
    font-weight: 500;
    line-height: 16.8px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $text-monarchy-gray-secondary-medium;
    @include m{
      font-size: 10px;
      line-height: 14px;
    }
  }
}