@import "src/styles/main";

.coinWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    animation: jump 0.3s;
  }
}

@keyframes jump {
  0% { transform: translateY(0); }
  50% { transform: translateY(-4px); }
  100% { transform: translateY(0); }
}

.dropdownContainer {
  background-color: #15181E;
  padding: 8px;
  border-radius: 8px;
  width: 396px;
  border: #35394A solid 1px;

  :global {
    .ant-dropdown-menu {
      padding: 0;
      border-radius: 0;
    }

    .ant-dropdown-menu-item {
      padding: 0 8px !important;
    }
  }
}

.tableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 16px 2px;

  @include m{
    font-size: 12px;
    font-weight: 500;
    line-height: 16.8px;
    color: $text-monarchy-gray-secondary-default;
    padding: 0 8px 6px;
  }
}

.coinImage {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 8px;
  @include m {
    height: 32px;
    width: 32px;
  }
}

.coinHeader {
  @include Heading_md20_600sb;
  color: $neutral-white;
  margin-right: 8px;
}

.arrow {
  color: $neutral-white;
  font-size: 16px;
  transition: all 300ms ease-in-out;

  &_up {
    transform: rotateZ(180deg);
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
  padding: 8px 0;
}

.pair {
  display: flex;
  align-items: center;
  width: 152px;
  @include Heading_xxs_14_600_sb;
  line-height: 1;

  .icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 8px;
  }

  .coinName {
    color: $neutral-white;
    margin-right: 4px;
    line-height: 1;
  }

  .coinNameOpacity {
    color: $text-monarchy-gray-secondary-default
  }
}

.price {
  display: flex;
  align-items: center;
  width: 112px;
  line-height: 1;

  &_value {
    color: $neutral-white !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    text-align: left;

  }
}

.change {
  display: flex;
  align-items: center;
  width: 92px;
  line-height: 1;

  &_green {
    color: $text-success-default;
  }

  &_red {
    color: $text-error-default;
  }
}

.drawerInnerWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.listContainer {
  overflow-y: auto;
  flex-grow: 1;
  :global{
    .ant-menu-inline{
      border: none!important;
    }
    .ant-menu-item{
      padding-left: 8px!important;
      padding-right: 8px!important;
    }
  }
}

.header {
  @include Heading_sm18_600sb;
  color: $neutral-white;
  margin-bottom: 12px;
}
.comingSoon{
  flex-grow: 1;
  text-align: center;
  color: $text-secondary-gray-default;
}
