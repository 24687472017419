@import 'src/styles/main';

.step {
  position: absolute;
  width: 820px;
  display: flex;
  justify-content: space-between;
  gap: 100px;
  margin-bottom: 24px;
  @include m {
    display: block;
    width: calc(100vw - 72px);
  }
  &_left {
    width: 320px;
    @include m {
      width: auto;
    }
    &_label {
      font-size: 12px;
      font-weight: 500;
      line-height: 17px;
      color: $text-secondary-gray-default;
      @include m {
        font-size: 11px;
        line-height: 15px;
      }
    }
    &_title {
      margin-top: 4px;
      @include Heading_xs16_600sb;
      color: #FFFFFF;
    }
    &_text {
      margin-top: 24px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $text-secondary-gray-medium;
    }
    &_subtext {
      margin-top: 14px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $text-secondary-gray-default;
      &_green {
        color: $text-success-default;
      }
      &_red {
        color: $text-error-default;
      }
    }

  }
  &_center {
    height: 100%;
    max-width: 39px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    @include m {
      left: 5%;
    }
    &_icon {
      width: 39px;
      height: 39px;
      background: #C0180C;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      @include m {
        width: 36px;
        height: 36px;
      }
    }
    &_line {
      top: 39px;
      left: 20px;
      position: absolute;
      width: 3px;
      height: 80%;
      background: linear-gradient(90deg, #C0180C 0%, rgba(192, 24, 12, 0.167708) 83.22%);
      box-shadow: 0 4px 10px 0 #C0180C;
      @include m {
        height: 90%;
        left: 18px;
        top: 36px;
      }
    }
  }
}

.lottie {
  @include m {
    margin-top: 16px;
  }
}
