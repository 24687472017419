.display {
  display: flex;
  gap: 8px;
  &_element {
    flex: 1 1;
    &:nth-child(2) {
      flex: 1 1;
      min-width: 150px;
    }
    &_label {
      font-size: 12px;
      line-height: 16px;
      color: #d7d8e0;
      margin-bottom: 8px;
    }
    &_inputWrapper {
      background: linear-gradient(180deg, #494F65 0%, #35394A 100%);
      padding: 1px;
      border-radius: 8px;
      &_input {
        height: 50px;
        background: #15181E;
        border: 1px solid var(--dark-monarchy-gray-secondary-200, rgba(54, 57, 66, 1));
        display: flex;
        align-items: center;
        border-radius: 8px;
        font-size: 14px;
        line-height: 24px;
        color: var(--dark-monarchy-gray-secondary-800, rgba(144, 150, 175, 1));
        font-weight: 600;
        padding: 0 5px 0 14px;
        max-width: 100%;
        & input {
          width: 100%;
          background: #15181E;
          border: none;
          outline: none;
          appearance: none;
          color: #fff;
          text-align: left;
          padding-left: 5px;
        }
      }
    }
    &_blockWrapper {
      background: linear-gradient(180deg, #494F65 0%, #35394A 100%);
      padding: 1px;
      border-radius: 8px;
      &_block {
        height: 50px;
        background: rgba(33, 36, 46, 0.5);
        border: 1px solid var(--dark-monarchy-gray-secondary-200, rgba(54, 57, 66, 1));
        display: flex;
        align-items: center;
        border-radius: 8px;
        font-size: 14px;
        line-height: 24px;
        color: var(--dark-monarchy-gray-secondary-800, rgba(144, 150, 175, 1));
        font-weight: 600;
        padding: 0 5px 0 14px;
        &_valid {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
}
