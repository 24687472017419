@import 'src/styles/main';


.wrapper {
  background: linear-gradient(180deg, #494F65 0%, #35394A 100%);
  border-radius: 8px;
  padding: 1px;
  max-width: 610px;
  @include l {
    width: 610px;
  }
}

.toggle {
  background: rgba(33, 36, 46, 1);
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  padding: 3px 4px;
  width: 100%;
  height: 40px;
  @include l {
    width: 608px;
  }
}

.inner {
  display: flex;
  align-items: center;
  position: relative;
  flex: 1 1 100%;
}

.item {
  color: rgba(144, 150, 175, 1);
  padding: 3px 0;
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  min-width: 150px;
  z-index: 1;
  height: 34px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  &_active {
    color: rgba(255, 255, 255, 1);
    -webkit-font-smoothing: antialiased;
    text-shadow: 0 2px 8px rgba(255, 255, 255, 0.5);
    & svg {
      filter: drop-shadow(0 2px 8px rgba(255, 255, 255, 0.5));
    }
  }
  &:hover {
    color: rgba(255, 255, 255, 1);
    -webkit-font-smoothing: antialiased;
    text-shadow: 0 2px 8px rgba(255, 255, 255, 0.5);
  }
}

.slicer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 8px;
  z-index: 0;
  font-size: 12px;
  font-weight: bold;
  transition: 0.3s transform;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  text-shadow: 0 1px 4px 0 rgba(255, 255, 255, 0.25), 0 0 2px 0 rgba(255, 255, 255, 0.25);
  background: linear-gradient(180deg, #35394A 0%, #494F69 100%);
  &:hover {
    background: linear-gradient(180deg, #41465A 0%, #5B617E 100%);
  }
}