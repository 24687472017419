@import "../../../styles/colors";
.status{
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  text-align: left;
  &_cashedOut{
    color: $text-monarchy-gray-secondary-default;
  }
  &_active{
    color: $text-success-default;
  }
}
