@import "src/styles/main";

.outer {
  border-radius: 8px;
  height: 44px;
  transition: all 150ms ease-in-out;
  padding: 1px;

  &_green {
    background: linear-gradient(180deg, rgba(18, 183, 106, 0.6) 0%, rgba(14, 138, 80, 0.6) 100%);

    &:focus-within {
      box-shadow: 0 0 0 4px $button-primary-green-focus;
    }
  }

  &_red {
    background: linear-gradient(180deg, rgba(241, 39, 25, 0.6) 0%, rgba(192, 24, 12, 0.6) 100%);

    &:focus-within {
      box-shadow: 0 0 0 4px $button-primary-red-focus;
    }
  }

  &_secondary {
    background: linear-gradient(180deg, rgba(110, 114, 133, 0.4) 0%, rgba(77, 80, 94, 0.4) 100%);

    &:hover {
      background: $button-secondary-focus;
    }

    //&:focus-within {
    //  box-shadow: 0 0 0 4px $button-secondary-focus;
    //}
  }
  &_loading{
    background: #074528;
    &:focus-within{
      box-shadow: none;
    }
  }

  &_disabled {
    background: $dark-secondary-gray!important;
  }
}

@mixin button-common {
  width: 100%;
  font-size: 16px;
  color: $neutral-white;
  height: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: none;
  transition: all 150ms ease-in-out;

  &[disabled] {
    cursor: initial;
    background: $dark-secondary-gray;
    color: $text-secondary-gray-disable;
  }
}

.button {
  @include button-common;


  &_green {
    background: $button-green;

    &:hover {
      background: $button-primary-green-hover;
    }

    &:active {
      background-color: $button-primary-green-active;
    }
  }

  &_red {
    background: $button-primary-red;

    &:hover {
      background: $button-primary-red-hover;
    }

    &:active {
      background-color: $button-primary-red-active;
    }
  }

  &_secondary {
    background: $button-secondary;

    &:hover {
      background: $button-secondary-hover;
    }

    &:active {
      background-color: $button-secondary-active;
    }

    &[disabled] {
      background: #35394A;
      color: #9096AF;
    }
  }

  &_loading{
    background-color: #074528!important;
    color: $Color-200;
  }
}

.loadingIcon {
  font-size: 20px;
  color: $neutral-white;
}

.icon{
  pointer-events: none;
}
