.popup {
  position: absolute;
  background: #373D4D;
  padding: 8px;
  border-radius: 8px;
  top: 15px;
  right: 40px;
  z-index: 1;
  &_item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 7px 8px;
    font-size: 16px;
    line-height: 25px;
    font-weight: 600;
    color: var(--text-monarchy-gray-secondary-default, #9096AF);
    background: transparent;
    cursor: pointer;
    border-radius: 6px;
    width: 100%;
    &:hover {
      color: #fff;
      background: var(--bg-secondary-gray-medium, #2C2F3C);
    }

    margin-bottom: 4px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}