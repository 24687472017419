@import "src/styles/main";

.betDriver {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #373D4D;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  z-index: 100;
  max-height: 95vh;
  border-top: 1px solid $border-secondary-gray-medium;
  transition: all 1500ms ease-in-out;
}

.widgetWrapper{
  overflow-y: auto;
  overflow-x: visible;
}

.draggerArea{
  position: absolute;
  top: -10px;
  left: 0;
  height: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .bar{
    background: #9096AF;
    width: 40px;
    height: 3px;
    border-radius: 100px;
    margin-top: 10px;
  }
}

.backdrop{
  position: fixed;
  //top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  opacity: 0;
  background-color: #000000;
  transition: opacity 100ms ease-in-out;
  &_opened{
    display: block;
    top: 0;
    opacity: .4;
    transition: opacity 100ms ease-in-out;
  }
}
