@import '../../styles/main';

.label {
  font-size: 12px;
  line-height: 16px;
  color: #d7d8e0;
  margin-bottom: 8px;
}

.segmentedWrapper {
  background: linear-gradient(180deg, #494F65 0%, #35394A 100%);
  padding: 1px;
  border-radius: 8px;
  :global {
    .ant-segmented {
      background: var(--neutral-black, rgba(10, 10, 14, 1));
      border-radius: 8px;
      box-shadow: 0 1px 2px 0 var(--dark-monarchy-gray-secondary-200, rgba(54, 57, 66, 1));
      padding: 4px;
      width: 100%;
      height: 42px;
    }
    .ant-segmented-item {
      color: #5e6272;
      padding: 3px 0;
      font-weight: bold;
      width: 100%;
    }
    .ant-segmented-item-selected {
      position: relative;
      color: rgba(255, 255, 255, 1);
      background:  linear-gradient(180deg, rgba(32, 192, 118, 0.8) 0%, rgba(0, 153, 82, 0.8) 100%);
      border-radius: 8px;
      &:hover {
        background: linear-gradient(180deg, rgba(32, 192, 118, 0.8) 0%, rgba(0, 153, 82, 0.8) 100%);
      }
      &::after {
        content: '';
        position: absolute;
        box-shadow: 0 0 6px 0 rgba(119, 255, 97, 0.3);
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
        border-radius: 8px 8px 7px 7px;
        background: radial-gradient(50% 50% at 50% 50%, #23C97C 0%, #009952 100%);
        pointer-events: none;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
      }
    }
    .ant-segmented-thumb {
      color: rgba(255, 255, 255, 1);
      background:  linear-gradient(180deg, rgba(32, 192, 118, 0.8) 0%, rgba(0, 153, 82, 0.8) 100%);
      border-radius: 8px;
      &:hover {
        background: linear-gradient(180deg, rgba(32, 192, 118, 0.8) 0%, rgba(0, 153, 82, 0.8) 100%);
      }
    }
  }

  &_down {
    :global {
      .ant-segmented-item-selected {
        position: relative;
        color: rgba(255, 255, 255, 1);
        background: linear-gradient(180deg, rgba(241, 39, 25, 0.8) 0%, rgba(192, 24, 12, 0.8) 100%);
        border-radius: 8px;
        &:hover {
          background: linear-gradient(180deg, rgba(241, 39, 25, 0.8) 0%, rgba(192, 24, 12, 0.8) 100%);
        }
        &::after {
          content: '';
          position: absolute;
          box-shadow: 0 0 6px 0 rgba(255, 108, 97, 0.3);
          top: 2px;
          left: 2px;
          right: 2px;
          bottom: 2px;
          border-radius: 8px 8px 7px 7px;
          background: radial-gradient(50% 50% at 50% 50%, #E42C1F 0%, #B11005 100%);
          pointer-events: none;
          width: calc(100% - 4px);
          height: calc(100% - 4px);
        }
      }
      .ant-segmented-thumb {
        color: rgba(255, 255, 255, 1);
        background: linear-gradient(180deg, rgba(241, 39, 25, 0.8) 0%, rgba(192, 24, 12, 0.8) 100%);
        border-radius: 8px;
        &:hover {
          background: linear-gradient(180deg, rgba(241, 39, 25, 0.8) 0%, rgba(192, 24, 12, 0.8) 100%);
        }
      }
    }
  }
}
