@import "src/styles/main";

.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  @include m{
    padding: 0 16px 16px;
  }
}

.button {
  font-size: 14px;
  font-weight: 400;
  color: $text-monarchy-gray-secondary-medium;
  height: 36px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $Color-600;
  padding: 0 14px;
  border: 4px solid;
  border-image-source: linear-gradient(180deg, #494F65 0%, #35394A 100%);



  .icon{
    margin-left: 8px;
  }

  &:hover {
    background: $button-secondary-active;
  }

}
