@import 'src/styles/main';

.label {
  font-size: 12px;
  line-height: 16px;
  color: #d7d8e0;
  margin-bottom: 8px;
}

.segmentedWrapper {
  margin-bottom: 24px;
  background: linear-gradient(180deg, #494F65 0%, #35394A 100%);
  border-radius: 8px;
  padding: 1px;
  :global {
    .ant-segmented {
      background: rgba(33, 36, 46, 1);
      border-radius: 8px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
      padding: 3px 4px;
      width: 100%;
      height: 40px;
    }
    .ant-segmented-item {
      color: rgba(144, 150, 175, 1);
      padding: 3px 0;
      font-weight: 700;
      width: 100%;
      &:hover {
        -webkit-font-smoothing: antialiased;
        text-shadow: 0 2px 8px rgba(255, 255, 255, 0.5);
      }
    }

    .ant-segmented-item-selected {
      color: rgba(255, 255, 255, 1);
      background: linear-gradient(180deg, #35394A 0%, #494F69 100%);
      -webkit-font-smoothing: antialiased;
      text-shadow: 0 2px 8px rgba(255, 255, 255, 0.5);
      &:hover {
        background: linear-gradient(180deg, #41465A 0%, #5B617E 100%);
      }
    }

    .ant-segmented-thumb {
      background: linear-gradient(180deg, #35394A 0%, #494F69 100%);
      border-radius: 8px;
    }
  }
}

