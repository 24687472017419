@import "../styles/colors";

.containerSuccess {
  background: #074528CC;
  border-radius: 8px;
  backdrop-filter: blur(8px);

  :global {
    .ant-notification-notice-message {
      color: $text-success-default !important;
    }
  }

  .icon {
    color: $text-success-default;
    font-size: 24px;
  }
}

.containerError {
  background: #682422CC;
  border-radius: 8px;
  backdrop-filter: blur(8px);

  :global {
    .ant-notification-notice-message {
      color: $text-error-default !important;
    }
  }

  .icon {
    color: $text-error-default;
    font-size: 24px;
  }
}

.cashOutMessage {
  background: $text-secondary-gray-weak;
  border: solid 1px $border-secondary-gray-medium;
  border-radius: 8px;
  backdrop-filter: blur(8px);
  color: #c21d1d;

  :global {
    .ant-notification-notice-message {
      color: $text-neutral-strong !important;
      font-size: 16px!important;
      font-weight: 500!important;
      line-height: 22.4px;
      text-align: left;
    }
    .ant-notification-notice-description {
      color: $text-monarchy-gray-secondary-default !important;
      font-size: 16px!important;
      font-weight: 500!important;
      line-height: 22.4px;
      text-align: left;
    }
  }

  .icon {
    height: 24px;
    width: 24px;
    border-radius: 50%;
  }
}
