@import 'src/styles/main';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;
  position: relative;
  z-index: 111;
  @include xl {
    flex-direction: column;
    align-items: flex-start;
  }
}

.segmentedWrapper {
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}