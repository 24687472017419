@import 'src/styles/main';

.wrapper {
  border-radius: 6px;
  background: linear-gradient(180deg, #494f65 0%, #35394a 100%);
  padding: 1px;
  transition: all 200ms ease-in-out;
  margin-bottom: 16px;

  @include m{
    margin-bottom: 8px;
    margin-right: 16px;
    width: fit-content;
  }

  &:hover {
    padding: 2px; // discussed
    margin: -1px -1px 15px -1px; // discussed
    transform: translateY(-4px);
    @include m {
      transform: none;
      margin: -1px -1px 7px -1px;
    }
  }
}

.inner {
  height: 85px;
  border-radius: 6px;
  background: #21242e;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;

  .icon{
    height: 44px;
    width: 44px;
    border-radius: 50%;
    @include m {
      height: 40px;
      width: 40px;
    }
  }

  @include m {
    height: 76px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.nameBlock {
  flex-grow: 2;
  max-width: 250px;
  @include m {
    width: 124px;
  }
}

.valueBlock {
  //width: 100px;
  //flex-grow: 1;
  @include m {
    width: 100px;
  }
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  text-align: left;
  color: $text-neutral-strong;
  display: block;
  font-family: 'RoobertPRO', Arial, sans-serif;
  @include m {
    font-size: 14px;
    line-height: 19.6px;
  }
}

.name {
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  text-align: left;
  color: $text-monarchy-gray-secondary-default;
  border-color: $text-monarchy-gray-secondary-default;
  @include m {
    font-size: 12px;
    line-height: 16.8px;
  }
}

.value {
  @include Heading_xs16_600sb;
  color: $text-neutral-strong;
  display: block;
}

.label {
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  text-align: left;
  color: $text-monarchy-gray-secondary-default;
  display: block;
  @include m {
    font-size: 12px;
    line-height: 16.8px;
  }
}

.change {
  display: flex;
  align-items: center;
  width: 92px;
  line-height: 1;

  &_green {
    color: $text-success-default;
  }

  &_red {
    color: $text-error-default;
  }
}