@import "src/styles/main";

.takeProfit {
  margin-top: 24px;
  @include m{
    margin-top: 16px;
  }
}

.label {
  font-size: 12px;
  line-height: 16px;
  color: #d7d8e0;
  margin-bottom: 8px;
}

.blocks {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.blockWrapper {
  background: linear-gradient(180deg, #494F65 0%, #35394A 100%);
  border-radius: 8px;
  padding: 1px;
}

.block {
  position: relative;
  & input {
    box-sizing: border-box;
    height: 50px;
    flex: 1 1 100%;
    background: #15181E;
    display: flex;
    align-items: center;
    border-radius: 8px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    overflow: hidden;
    width: 100%;
    border: 1px solid  #35394A;
    outline: none;
    appearance: none;
    color: #fff;
    text-align: left;
    padding: 0 14px;
    &::placeholder {
      font-size: 16px;
      line-height: 22px;
      color: var(--dark-monarchy-gray-secondary-800, #9096AF);
    }
    &:focus {
      border: 1px solid $dark-success-600
    }
  }
  & span {
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    position: absolute;
    color: var(--text-success-default, rgba(18, 183, 106, 1));
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
  }
  &_error {
    margin-top: 8px;
    font-size: 11px;
    font-weight: 500;
    line-height: 15px;
    color: $text-error-default;
  }
}

