@import '../../../styles/colors';
@import "../../../styles/commonStyles";

.buttonsContainer {
  display: flex;
  align-items: center;
}

.loadingIcon {
  font-size: 20px;
  color: $neutral-white;
}

.icon {
  font-size: 22px;
  color: $text-monarchy-gray-secondary-default;
}

.button{
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
}

.iconButton {
  background: transparent;
  height: 34px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  &:hover {
    .icon {
      color: $neutral-white;
    }
  }

  &:active,
  :focus {
    .icon {
      opacity: 0.8;
    }
  }
}
