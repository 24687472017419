@import "src/styles/main";

.appContainer {
  padding: 40px;
  //background: linear-gradient(180deg, #0D0E12 0%, #16181F 100%);
  //background: rgba(10, 10, 14, 1);
  background: #0a0a0e;
  min-height: 100vh;
  @include l{
    padding: 16px;
  }
  @include m {
    padding: 16px 0 0;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    overscroll-behavior: none;
  }
}
