@import "src/styles/main";

.wrapper {
  background: linear-gradient(180deg, #494F65 0%, #35394A 100%);
  padding: 1px;
  border-radius: 8px;
  position: relative;
  flex-grow: 1;
  transition: 0.3s ease-in-out;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    background: conic-gradient(from 180deg at 50% 50%,
            #494F65 0deg,
            #494F65 55.45deg,
            #ADB8E0 130.37deg,
            #494F65 231.95deg,
            #868FB0 310.2deg,
            #494F65 360deg
    );
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  &:hover::before {
    opacity: 1;
  }
}

.select {
  font-size: 14px;
  font-weight: 400;
  color: $text-monarchy-gray-secondary-medium;

  height: 36px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $Color-600;
  flex-grow: 1;

  :global{
    .ant-select-selector:after{
      content: none!important;
    }
    .ant-select-selection-item{
      color: #D7D8E0!important;
    }
    .ant-select-arrow {
      display: none;
    }
  }

}

.popup{
  border: #35394A 1px solid;
  padding: 12px 8px;
}

.icon {
  position: absolute;
  top: 25%;
  right: 8px;
  transition: 0.3s all;
  color: #9096AF;
  pointer-events: none;
  transform: scale(-1);

  &_opened{
    transform: scale(1);
  }
}

.dropdown {
  border: solid 1px #35394A;
  border-radius:8px;
  :global{
    .ant-dropdown-menu-item-active{
      color: #fff!important;
    }
  }
}
